import owasp from 'owasp-password-strength-test';
import { generateJSON, ReactRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

export const MIN_PASSWORD_LENGTH = 10;
export const INPUT_SIZES = ['small', 'large', 'medium']; // medium is the default

/*
  Configure owasp
  Check here: https://github.com/nowsecure/owasp-password-strength-test/blob/master/README.md
*/
owasp.config({
  minLength: MIN_PASSWORD_LENGTH,
  allowPassphrases: false, // this allows us to get an even score
});

export const passwordStrengthMap = {
  1: { message: 'weak', strengthClass: 'high' },
  2: { message: 'Just OK', strengthClass: 'med' },
  3: { message: 'Good', strengthClass: 'low' },
  4: { message: 'Great!', strengthClass: 'great' },
};

export function getPasswordStrength(password) {
  if (password.length >= MIN_PASSWORD_LENGTH) {
    const { passedTests, failedTests } = owasp.test(password);
    const maxStrength = Object.values(passwordStrengthMap).length;
    const minStrength = 1;
    const totalTests = passedTests.length + failedTests.length;
    const calculatedStrength = (passedTests.length * maxStrength) / totalTests;
    const strength = Math.max(minStrength, Math.floor(calculatedStrength));

    return { ...passwordStrengthMap[strength], level: strength };
  }

  return { level: 0, strengthClass: null, message: null };
}

/**
 * Function gives a component the required validation for either a label or an aria-label prop
 * @param {object} props
 * @param {string} _ - prop Name - (not used)
 * @param {string} componentName - Name of the component that is using the validation.
 * @returns {*} Either an error that is thrown on the console or nothing if valid
 */
export const requiredLabelPropCheck = (props, _, componentName) => {
  if (!props['aria-label'] && !props.label) {
    return new Error(`One of 'aria-label' or 'label' is required by '${componentName}' component.`);
  }

  return false;
};

/**
 * Gets the values from our options
 * @param {array || object} options - An array or a single { value } object
 * @param {boolean} isMulti - Boolean that determines if select is a multiselect
 * @returns {*} an array or single value
 */
export const getValuesFromOptions = (options, isMulti) => (
  isMulti
    ? options?.map(option => option?.value) ?? []
    : options?.value ?? null
);

/**
 * Determines if a select's value is not empty
 * @param {*} value- An array of values or a single value
 * @param {boolean} isMulti - Boolean that determines if select is a multiselect
 * @returns {boolean}
 */
export const isSelectValueNotEmpty = (value, isMulti) => (isMulti
  ? value && Array.isArray(value) && value.length
  : Boolean(value));

/**
 * Checks if values are found in the selected options
 * @param {*} values - An array of values or a single value
 * @param {*} selectedOptions - An array or a single { value } object
 * @param {boolean} isMulti - Boolean that determines if select is a multiselect
 * @returns {boolean}
 */
export const doValuesMatchSelectedOptions = (values, selectedOptions, isMulti) => {
  const areSameType = isMulti
    ? Array.isArray(values) && Array.isArray(selectedOptions)
    : !Array.isArray(values) && !Array.isArray(selectedOptions);

  if (areSameType) {
    return isMulti
      ? values.length === selectedOptions.length && (
        values.every(value => selectedOptions.some(option => option?.value === value))
      )
      : selectedOptions?.value === values;
  }

  return false;
};

/**
 * Returns an object containing two arrays:
 * cachedOptoins - the first array is an array of options found in the cache of options
 * uncachedValues - the second array is an array of values that had no match with cached options
 * @param {*} value - An array of primitives or a single primitive
 * @param {*} cachedOption an option object of { value } or an array of options
 * @returns {object}
 */
export const groupValuesByCachedOptionsAndUncachedValues = (value, cachedOption) => {
  const values = Array.isArray(value) ? value : [value];
  const cachedOptions = Array.isArray(cachedOption) ? cachedOption : [cachedOption];

  return values.reduce((group, value) => {
    const cached = cachedOptions.find(option => option?.value === value);

    if (cached) return { ...group, cachedOptions: [...group.cachedOptions, cached] };

    return { ...group, uncachedValues: [...group.uncachedValues, value] };
  }, { cachedOptions: [], uncachedValues: [] });
};

/**
 * Merges cached options by ensuring that fixed options take precedence over selected options
 * @param {*} selectedOption - an option object of { value } or an array of options
 * @param {array} fixedOptions - an array of fixed option objects { value }
 * @returns {array}
 */
export const mergeSelectedAndFixedOptions = (selectedOption, fixedOptions = []) => {
  const selectedOptions = Array.isArray(selectedOption) ? selectedOption : [selectedOption];

  return selectedOptions.reduce((merged, selectedOption) => {
    const isFixed = fixedOptions.some(option => option.value === selectedOption?.value);

    return isFixed || !selectedOption ? merged : [...merged, selectedOption];
  }, fixedOptions || []);
};

/**
 * Returns whether TipTap JSON content is empty.
 * This does not apply to Draftjs editor.
 * @param {string | object} value
 * @returns
 */
export const isRichTextInputEmpty = (value) => {
  const emptyValue = generateJSON('', [StarterKit]);
  const stringifiedValue = JSON.stringify(value);

  return value && stringifiedValue !== '{}'
    ? JSON.stringify(emptyValue) === stringifiedValue
    : true;
};

/**
 * Cleans up empty json content for the rich text editor
 * @param {string | object} value
 * @returns
 */
export const getValidRichTextValue = (value) => {
  if (typeof value === 'object') {
    return value?.type === 'doc' ? value : '';
  }

  return value;
};

/**
 * Returns TipTap rich text content from plain text.
 * https://tiptap.dev/docs/editor/api/schema
 * @param {string} text
 * @returns {object}
 */
export const getRichTextFromPlainText = text => (
  generateJSON(`<p>${text}</p>`, [StarterKit])
);

/**
 * Get suggestion configuration for tiptap mention suggestions
 * @param {FC} MentionUserList
 * @param {object} userFilters
 * @returns
 */
export const getMentionSuggestions = (MentionUserList, userFilters = {}) => ({
  render: () => {
    let renderer;

    return {
      onStart: (props) => {
        renderer = new ReactRenderer(MentionUserList, {
          props: { ...props, userFilters },
          editor: props.editor,
        });
      },
      onUpdate(props) {
        renderer?.updateProps(props);
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          renderer?.destroy();
          return true;
        }

        return renderer?.ref?.onKeyDown(props);
      },
      onExit() {
        renderer?.destroy();
      },
    };
  },
});
